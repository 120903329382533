import {React} from 'components';
import {link, colors} from './helpers';
import {Link} from 'react-router-dom';

// istanbul ignore file

export default {
  key: 'orders-payments',
  title: 'Orders & Payments',
  sections: [
    {
      layout: 'accordionWithMedia',
      heightAuto: true,
      defaultMedia: [{orientation: 'square', src: link('orders-payments/orders-payments-1.jpg')}],
      accordionTitle: 'Orders & Payments',
      accordionSections: [
        {
          title: 'Orders',
          text: {
            items: [
              {render: () => (
                <div>
                  <b>What is your lead time?</b><br/>
                  Your project is built to order once you approve your final drawings. We provide inside delivery of your project as fast as 18 weeks from the date you sign off your drawings for production. Our team will work with you as you go through the process to dial in the specific delivery window that works for you.
                </div>)
              },
              {render: () => (
                <div>
                  <b>How much does a kitchen cost?</b><br/>
                  Our <Link className='accordion-text-item-link blue' to={'/page/pricing-with-examples'}>pricing examples </Link>by kitchen layout are a great way to get an idea of pricing.
                </div>)
              },
              {render: ({showInquiryForm}) => (
                <div>
                  For pricing specific to your project, <span className='accordion-text-item-link blue' onClick={() => showInquiryForm()}>get in touch</span> to get detailed pricing specific to your project.
                </div>)
              },
              {render: () => (
                <div>
                  <b>Is there a minimum order?</b><br/>
                  Our minimum order is $10,000.
                </div>)
              }
            ]
          }
        },
        {
          title: 'Payments',
          text: {
            items: [
              {render: () => (
                <div>
                  <b>How do deposits and payments work?</b><br/>
                  Once you have made the decision to work with us, a signed contract and 50% deposit starts the process. At final design approval, 25% is due to start production and the remaining 25% is payable 6 weeks after the project goes into production.                 </div>)
              },
              {render: () => (
                <div>
                  <b>What are the accepted payment methods?</b><br/>
                  Payments may be made by check, wire transfer, Visa, or Mastercard.
                </div>)
              }
            ]
          }
        },
      ]
    }
  ]
};
