import {gridCellGroupsFor} from './helpers';
import {React} from 'components';
import {Link} from 'react-router-dom';

export default {
  key: 'materials-and-finishes',
  title: 'Materials and Finishes',
  sections: [
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          {className: 'sub-heading', text: 'Materials and Finishes'},
          `We have more than forty selections of finishes ranging from natural wood to laminate to aluminum making it easier to fully integrate into nearly every space.`,
        ]
      }
    },
    {
      id: 'tier-1',
      layout: 'textLeftGridRight',
      textStyle: 'details',
      heightAuto: true,
      gridProps: {cellSizeMode: 'small'},
      text: {items: [
        {className: 'heading', text: 'Tier 1'},
        `Fronts
        End Panels
        Wall Panels
        Backsplashes
        Finished Boxes`
      ]},
      cellGroups: gridCellGroupsFor({page: 'materials', section: 'tier-1', groups: [
        {title: 'Natural Veneer', showGroupTitle: true, cells: [
          {title: 'Muted Oak (rift sawn or plain sawn)', key: 'muted-oak', details: {imageCount: 3}},
          {title: 'Natural Walnut', key: 'natural-walnut', details: {}}
        ]},
        {title: 'Technical Laminate with Color Matched Edge', showGroupTitle: true, cells: [
          {title: 'Honey Plantain', key: 'honey-plantain', details: {}},
          {title: 'Liberty Red', key: 'liberty-red', details: {}},
          {title: 'Cafe Sienna', key: 'cafe-sienna', details: {}},
          {title: 'Just Rose', key: 'just-rose', details: {}},
          {title: 'Liquid Glass', key: 'liquid-glass', details: {}},
          {title: 'Azzurre', key: 'storm-blue', details: {}},
          {title: 'Raven', key: 'raven', details: {}},
          {title: 'Storm Gray', key: 'storm-gray', details: {}},
          {title: 'Maritime Gray', key: 'maritime-gray', details: {}},
          {title: 'Bankers Gray', key: 'bankers-gray', details: {}},
          {title: 'Moss Gray', key: 'moss-gray', details: {}},
          {title: 'Aqua Lagoon', key: 'aqua-lagoon', details: {}},
          {title: 'Deep Blue', key: 'deep-blue', details: {}},
          {title: 'Slate', key: 'slate', details: {}},
          {title: 'Stonedust', key: 'stonedust', details: {}},
          {title: 'Folkstone Gray', key: 'folkstone-gray', details: {}},
          {title: 'Smoke White', key: 'smoke-white', details: {}},
          {title: 'Precision White', key: 'precision-white', details: {}},
        ]}
      ]})
    },
    {
      id: 'tier-2',
      layout: 'textLeftGridRight',
      textStyle: 'details',
      heightAuto: true,
      gridProps: {cellSizeMode: 'small'},
      text: {items: [
        {className: 'heading', text: 'Tier 2'},
        `Fronts
        End Panels
        Wall Panels
        Backsplashes
        Finished Boxes`
      ]},
      cellGroups: gridCellGroupsFor({page: 'materials', section: 'tier-2', groups: [
        {title: 'Stained Veneer', showGroupTitle: true, cells: [
          {title: 'Ivory Oak', key: 'ivory-oak', details: {}},
          {title: 'Gray Oak', key: 'gray-oak', details: {}},
          {title: 'Black Oak', key: 'black-oak', details: {}},
        ]},
        {cells: [
          {title: 'Muted Walnut', key: 'muted-walnut', details: {}},
          {title: 'Roasted Walnut', key: 'roasted-walnut', details: {}},
          {title: 'Black Walnut', key: 'black-walnut', details: {}}
        ]},
        {cells: [
          {title: 'White Walnut', key: 'white-walnut', details: {}},
          {title: 'Platinum Walnut', key: 'platinum-walnut', details: {notes: []}},
          {title: 'Shadow Gray Walnut', key: 'shadow-gray-walnut', details: {}},
        ]},
        {cells: [
          {title: 'Storm Blue Walnut', key: 'storm-blue-walnut', details: {}},
          {title: 'Ocean Blue Walnut', key: 'ocean-blue-walnut', details: {}},
          {title: 'Midnight Blue Walnut', key: 'midnight-blue-walnut', details: {}},
        ]},
        {cells: [
          {title: 'Ochre Red Walnut', key: 'ochre-red-walnut', details: {}},
          {title: 'Forest Green Walnut', key: 'forest-green-walnut', details: {}}
        ]},
        {title: 'Soft Touch Laminate with Color Matched Edge', showGroupTitle: true, cells: [
          {title: 'Alpine White', key: 'alpine-white', details: {}},
          {title: 'Cashmere Grey', key: 'cashmere-grey', details: {}},
          {title: 'Dust Grey', key: 'dust-grey', details: {}},
          {title: 'Graphite Grey', key: 'graphite-grey', details: {}},
          {title: 'black', key: 'black', details: {}}
        ]}
      ]})
    },
    {
      layout: 'textLeftGridRight',
      textStyle: 'details',
      heightAuto: true,
      gridProps: {cellSizeMode: 'small'},
      text: {items: [
        {className: 'heading', text: 'Solid Surface'},
        `Work Surfaces
        End Panels
        Backsplashes`
      ]},
      cellGroups: gridCellGroupsFor({page: 'materials', section: 'solid-surface', groups: [
        {cells: [
          {title: 'White', key: 'white', details: {}},
          {title: 'Ivory (work surfaces only)', key: 'ivory', details: {}},
          {title: 'Cloud (work surfaces only)', key: 'cloud', details: {}},
          {title: 'Paperstone-Onyx', key: 'paperstone-onyx', details: {}},
        ]}
      ]})
    },
    {
      layout: 'textLeftGridRight',
      textStyle: 'details',
      heightAuto: true,
      gridProps: {cellSizeMode: 'small'},
      text: {items: [
        {className: 'heading', text: 'Powdercoat'},
        `End Panels
        Opencase Shelves
        Storage Bin`
      ]},
      cellGroups: gridCellGroupsFor({page: 'materials', section: 'powdercoat-metal', groups: [
        {cells: [
          {title: 'White', key: 'white', details: {}},
          {title: 'Light Gray', key: 'light-gray', details: {}},
          {title: 'Dark Gray', key: 'telegray', details: {}},
          {title: 'Black Satin', key: 'black-gray', details: {}}
        ]}
      ]})
    },
    {
      layout: 'textLeftGridRight',
      textStyle: 'details',
      heightAuto: true,
      gridProps: {cellSizeMode: 'small'},
      text: {items: [
        {className: 'heading', text: 'Anodized Aluminum'},
        `Pulls
        Opencase Rods
        Backsplashes (Clear Only)`
      ]},
      cellGroups: gridCellGroupsFor({page: 'materials', section: 'aluminum', groups: [
        {cells: [
          {title: 'Clear aluminum', key: 'clear-aluminum', details: {}},
          {title: 'Black aluminum', key: 'black-aluminum', details: {}},
        ]}
      ]})
    },
  ]
};
