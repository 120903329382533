import {React} from 'components';
import {link} from './helpers';

// istanbul ignore file

export default {
  key: 'pricing-with-examples',
  title: 'Pricing with Examples',
  sections: [
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          {className: 'sub-heading', text: 'Pricing with Examples'},
          `Our premium product range offers a complete system including storage units, pulls, integrated lighting, modular interior accessories, countertops, backsplash, and sinks. You can purchase the complete package or only what you need. The necessary design consultation to achieve a great result is included in the prices below.`,
        ]
      }
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          `Your Space Theory kitchen will be made to order in Henrybuilt's production facility in Seattle. Space Theory kitchens can start as low as $27,500 with most packages being in the $49,500 to $66,000 range. If you have questions about your budget, we'd be happy to talk with you about options that will be a good fit for you and your space.`,
          `Our pricing is transparent and accessible through our design software as we work with you, so you can easily see the effect of specific choices on the overall cost.`,
          `Below are some design scenarios with pricing to give you an idea of what you can achieve.`

        ]
      },
      media: [{orientation: 'square', src: link('pricing-with-examples/pricing-with-examples-2.jpg')}]
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          {className: 'heading', text: 'Design Scenario 1'},
          `Price: $28,765`,
          {className: 'sub-heading', text: `Components:`},
          `Two 3-drawer units and interior component system. Floating shelf, sink and oven units, integrated trash and recycling, a tall pantry, and panels to integrate dishwasher and refrigerator. Pulls are included.`,
          `Add work surfaces and sink: $35,610`,
          `Add an Opencase Backsplash: $3,7285 `
        ]
      },
      media: [{orientation: 'square', src: link('pricing-with-examples/rendering-4.jpg')}]
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          {className: 'heading', text: 'Design Scenario 2'},
          `Price without work surfaces or sink: $40,160`,
          {className: 'sub-heading', text: `Components:`},
          `Four 3-drawer units and interior component system. Furniture-quality wall unit, sink unit, oven unit, 2 corner units, and an integrated trash and recycling unit. Plus panels to integrate dishwasher and refrigerator. Pulls are included.`,
          `Add countertops and sink: $47,730`
        ]
      },
      media: [{orientation: 'square', src: link('pricing-with-examples/rendering-1.jpg')}]
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          {className: 'heading', text: 'Design Scenario 3'},
          `Price without work surfaces or sink: $42,650`,
          {className: 'sub-heading', text: `Components:`},
          `Three 3-drawer units and interior component system. Furniture-quality wall units with integrated lighting, sink unit, cooktop/oven unit, integrated trash and recycling unit. Appliance tower with drawers. Plus panels to integrate dishwasher and refrigerator. Pulls are included.`,
          `Add countertops and sink: $49,580`
        ]
      },
      media: [{orientation: 'square', src: link('pricing-with-examples/rendering-2.jpg')}]
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          {className: 'heading', text: 'Design Scenario 4'},
          `Price without work surfaces or sink: $48,235`,
          {className: 'sub-heading', text: `Components:`},
          `Four 3-drawer units and interior component system. Floating shelves with integrated lighting, sink unit, cooktop/oven unit, integrated trash and recycling unit. Appliance tower with drawers. Two tall pantries. Plus panels to integrate dishwasher and refrigerator. Pulls are included.`,
          `Add countertops and sink: $56,500`
        ]
      },
      media: [{orientation: 'square', src: link('pricing-with-examples/rendering-3.jpg')}]
    },
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          {render: ({showInquiryForm}) => (
            <div>
              <span style={{color: 'rgb(119, 152, 173)', cursor: 'pointer'}} onClick={() => showInquiryForm({contactReason: 'project'})}>Get in touch</span> to get started.
            </div>
          )},
          {className: 'smaller-text-space-after', text: 'Subscribe to get the latest news and updates from Space Theory.'},
          {render: () => (
            <div className='subscribe-form-container'>
              <iframe src='/subscribe-form' title='subscribe-form'></iframe>
            </div>
          )}
        ]
      }
    }
  ]
};
