import {_, React, Component, className} from 'components';
import Form from 'lib/form/form';
import Typing from 'react-typing-animation';
import './ex-machina-inquiry-form.scss';

var salespeople = {
  leslie: {name: 'Leslie DiCorpo', phone: '415.360.2821', email: 'leslie@henrybuilt.com'},
  chris: {name: 'Chris Barriatua', phone: '415.360.2905', phoneSuffix: 'call or text', email: 'chris@spacetheory.com'},
  lisa: {name: 'Lisa Day', phone: '206.454.3160', email: 'lisa@spacetheory.com'},
  michelle: {name: 'Michelle Kalsi', phone: '206.406.6145', email: 'michelle@spacetheory.com'}
};

var typingProps =  {
  hideCursor: true,
  speed: 30
};

function SalespersonInfo({name}) {
  var person = salespeople[name];
  var contactInfo;

  if (name === 'california') {
    contactInfo = () => {
      return (
        <>
          <div className='name'>
            <span>Our Teams in California</span><span className='name-suffix'>WILL BE HAPPY TO HELP</span>
          </div>
          <div className='info'>
            <div>Northern California</div>
            <a className='phone' href={`tel:415.802.0901`}>415.802.0901</a>
            <a className='email' href={`mailto:bayarea@spacetheory.com`}>bayarea@spacetheory.com</a>
            <br/>
            <div>Southern California</div>
            <a className='phone' href={`tel:310.299.2441`}>310.299.2441</a>
            <a className='email' href={`mailto:losangeles@spacetheory.com`}>losangeles@spacetheory.com</a>
          </div>
        </>
      );
    };
  }
  else if (name === 'newyork') {
    contactInfo = () => {
      return (
        <>
          <div className='name'>
            <span>Our Team in New York</span><span className='name-suffix'>WILL BE HAPPY TO HELP</span>
          </div>
          <div className='info'>
            <a className='phone' href={`tel:646.454.9696`}>646.454.9696</a>
            <a className='email' href={`mailto:newyork@spacetheory.com`}>newyork@spacetheory.com</a>
          </div>
        </>
      );
    };
  }
  else {
    contactInfo = () => {
      return (
        <>
          <div className='name'>
            <span>{person.name}</span><span className='name-suffix'>WILL BE HAPPY TO HELP</span>
          </div>
          <div className='info'>
            <a className='phone' href={`tel:${person.phone}`}>{person.phone}</a>
            {person.phoneSuffix && (<span className='phone-suffix'>{person.phoneSuffix}</span>)}
            <a className='email' href={`mailto:${person.email}`}>{person.email}</a>
          </div>
        </>
      );
    };
  }

  return (
    <div {...className(['sales-contact', name])}>
      {contactInfo()}
      <Typing key={Math.random()} {...{...typingProps, delay: 200}} className='thank-you-message'>
        THANK YOU FOR YOUR INTEREST
      </Typing>
    </div>
  );
}

export default class ExMachinaInquiryForm extends Component {
  constructor(props) {
    super(props);

    this.state = {isInUnitedStates: '', isInCanada: '', stateUSA: ''};

    this.baseState = this.state;

    this.handleChange = this.handleChange.bind(this);
    this.handleInput = _.debounce(this.handleInput.bind(this), 200);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.inquiryFormIsVisible !== this.props.inquiryFormIsVisible) {
      if (!this.props.inquiryFormIsVisible) this.resetForm();
    }
  }

  resetForm() {
    this.setState(this.baseState);
  }

  handleChange({name, value}) {
    this.setState({[name]: value});
  }

  handleInput({name, value}) {
    this.setState({[name]: value});
  }

  inputPropsFor({name}) {
    return {
      on: {
        change: ({value}) => this.handleChange({name, value}),
        input: ({value}) => this.handleInput({name, value})
      },
      value: this.state[name],
      name
    };
  }

  salesContactFor({name, stateUSA}) {
    stateUSA = _.lowerCase(_.toString(stateUSA));

    if (!name && stateUSA) {
      var state = _.find(this.allStates, ({name, abbreviation}) => stateUSA === name || stateUSA === abbreviation || (stateUSA.length > 2 && _.includes(name, stateUSA)));

      if (state) {
        name = 'lisa';

        if (_.includes(['wy', 'mt', 'co', 'ut', 'tx', 'vi'], state.abbreviation)) {
          name = 'chris';
        }

        if (_.includes(['ny', 'nj', 'ct', 'ma', 'ri', 'pa', 'md', 'de', 'dc', 'va'], state.abbreviation)) {
          name = 'newyork';
        }

        if (_.includes(['ca'], state.abbreviation)) {
          name = 'california';
        }
      }
    }

    return name ? <SalespersonInfo {...{name}}/> : null;
  }

  render() {
    var {isInUnitedStates, isInCanada, stateUSA} = this.state;
    var {inquiryFormIsVisible} = this.props;

    return !inquiryFormIsVisible ? null : (
      <div {...className(['ex-machina-inquiry-form', 'theme-light'])}>
        <div className='form-container'>
          <div className='inputs-container'>
            <div className='input-wrapper'>
              <Typing className='label' key={'is-in-united-states'} {...typingProps}>
                IS YOUR PROJECT IN THE UNITED STATES?
              </Typing>
              <Form.RadioInput {...this.inputPropsFor({name: 'isInUnitedStates'})} options={[
                {title: 'Yes', value: 'yes'},
                {title: 'No', value: 'no'}
              ]}/>
            </div>
              {isInUnitedStates === 'yes' && (
                <div className='input-wrapper'>
                  <Typing className='label' key={'state-usa'} {...typingProps}>
                    WHICH STATE?
                  </Typing>
                  <Form.TextInput {...this.inputPropsFor({name: 'stateUSA'})}/>
                </div>
              )}
              {isInUnitedStates === 'no' && (
                <div className='input-wrapper'>
                  <Typing className='label' key={'is-in-canada'} {...typingProps}>
                    IS YOUR PROJECT IN CANADA?
                  </Typing>
                  <Form.RadioInput {...this.inputPropsFor({name: 'isInCanada'})} options={[
                    {title: 'Yes', value: 'yes'},
                    {title: 'No', value: 'no'}
                  ]}/>
                </div>
              )}
          </div>
          <div className='sales-contact-container'>
            {(isInUnitedStates === 'yes' && stateUSA.length >= 2) && this.salesContactFor({stateUSA})}
            {(isInUnitedStates === 'no' && isInCanada === 'yes') && this.salesContactFor({name: 'lisa'})}
            {(isInUnitedStates === 'no' && isInCanada === 'no') && this.salesContactFor({name: 'chris'})}
          </div>
        </div>
      </div>
    );
  }

  get keyedStates() {
    return {
      "AL": "Alabama",
      "AK": "Alaska",
      "AS": "American Samoa",
      "AZ": "Arizona",
      "AR": "Arkansas",
      "CA": "California",
      "CO": "Colorado",
      "CT": "Connecticut",
      "DE": "Delaware",
      "DC": "District Of Columbia",
      "FM": "Federated States Of Micronesia",
      "FL": "Florida",
      "GA": "Georgia",
      "GU": "Guam",
      "HI": "Hawaii",
      "ID": "Idaho",
      "IL": "Illinois",
      "IN": "Indiana",
      "IA": "Iowa",
      "KS": "Kansas",
      "KY": "Kentucky",
      "LA": "Louisiana",
      "ME": "Maine",
      "MH": "Marshall Islands",
      "MD": "Maryland",
      "MA": "Massachusetts",
      "MI": "Michigan",
      "MN": "Minnesota",
      "MS": "Mississippi",
      "MO": "Missouri",
      "MT": "Montana",
      "NE": "Nebraska",
      "NV": "Nevada",
      "NH": "New Hampshire",
      "NJ": "New Jersey",
      "NM": "New Mexico",
      "NY": "New York",
      "NC": "North Carolina",
      "ND": "North Dakota",
      "MP": "Northern Mariana Islands",
      "OH": "Ohio",
      "OK": "Oklahoma",
      "OR": "Oregon",
      "PW": "Palau",
      "PA": "Pennsylvania",
      "PR": "Puerto Rico",
      "RI": "Rhode Island",
      "SC": "South Carolina",
      "SD": "South Dakota",
      "TN": "Tennessee",
      "TX": "Texas",
      "UT": "Utah",
      "VT": "Vermont",
      "VI": "Virgin Islands",
      "VA": "Virginia",
      "WA": "Washington",
      "WV": "West Virginia",
      "WI": "Wisconsin",
      "WY": "Wyoming"
    };
  }

  get allStates() {
    return _.map(this.keyedStates, (name, abbreviation) => ({name: name.toLowerCase(), abbreviation: abbreviation.toLowerCase()}));
  }
}
