import {React} from 'components';
import {link} from './helpers';
import {Link} from 'react-router-dom';

// istanbul ignore file

export default {
  key: 'a-natural-system',
  title: 'A Natural System',
  sections: [
    {
      layout: 'media',
      media: [{
        orientation: 'horizontal',
        src: link('a-natural-system/2.jpg'),
        overlay: {
          render: () => (
            <div className='project-story-title-container'>
              <div className='project-story-title'>A Natural System</div>
            </div>
          )
        }
      }],
    },
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          {className: 'smaller-text-space-after', text: `A Bay Area cell biologist views her San Francisco home as a rare glimpse into nature in an otherwise super-urban environment. Overlooking the city’s natural landscape, the house’s remodel was an opportunity for her family to surround themselves in nature as much as possible and extend the peaceful wooded environment  inward—starting with the kitchen.`},
          {className: 'smaller-text-space-after', text: `Having moved 10 times in the past 10 years, the homeowner had experienced numerous approaches to design, including the simplified, unembellished style of the Swiss. “I was deeply inspired by how the simplicity and understated elegance blended seamlessly with the surrounding nature in Switzerland," she says. The experience motivated her to create a kitchen that feels like an integral part of nature, rather than something that stands apart. “The stunning oak finish from Space Theory helped bring this vision to life perfectly.”`},
          {render: () => (
            <div className='page-section-text-item text-item-sub-heading is-visible'>
              <div>San Francisco, California</div>
              <div>Interior Design: Florena Nemteanu, Vis à Vis Studio</div>
              <div>Architect: Rex McLean</div>
              <div>Construction: GBI Construction and Vonnegut Thoreau Construction</div>
            </div>
          )}
        ]
      }
    },
    {
      layout: 'media',
      media: [{orientation: 'horizontal', src: link('a-natural-system/1.jpg')}],
    },
    {
      layout: 'doubleMedia',
      media: [
        {orientation: 'square', src: link('a-natural-system/3a.jpg')},
        {orientation: 'square', src: link('a-natural-system/3b.jpg')}
      ],
    },
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          {className: 'smaller-text', text: `Avid cooks and hosts, the homeowners kept the kitchen at the front of their minds when they began the remodel. The couple worked with Florena Nemteanu of Vis à Vis Studio to bring their light-filled vision to life, taking down a central wall to open up the kitchen for brightness—and to create a large standing space for gathering with friends and family. The initial downside of removing the wall was a decrease in storage, which Space Theory resolved using its design expertise and flexible, built-in functional components, optimizing the space for daily use.`}
        ]
      }
    },
    {
      layout: 'media',
      media: [{orientation: 'horizontal', src: link('a-natural-system/4.jpg')}],
    },
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          {className: 'smaller-text', text: `“When my client approached me, she expressed a clear vision: her kitchen should harmonize with the breathtaking natural surroundings of the Bay Area,” Nemteanu says. “She wanted a space that felt serene yet functional, organic yet modern—a reflection of the environment she cherishes. Together, we partnered with Space Theory to craft a kitchen that celebrates nature through impeccable design and functional innovation. Every detail, from the custom finishes to the seamless integration of the system, was chosen to bring the outdoors in, creating a timeless and inviting space. This project perfectly embodies my philosophy at Vis à Vis Studio: design that is deeply personal, effortlessly elegant, and always in dialogue with its surroundings.”`}
        ]
      }
    },
    {
      layout: 'doubleMedia',
      media: [
        {orientation: 'square', src: link('a-natural-system/5a.jpg')},
        {orientation: 'square', src: link('a-natural-system/5b.jpg')}
      ],
    },
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          {className: 'smaller-text-space-after', text: `The homeowner describes Space Theory’s system approach like Legos in her 4-year-old’s mind: it’s not static, and you can rearrange it as time goes on. She believes that as nature ages with us, it becomes better over time, and the Space Theory kitchen system is able to do the same: always changing and adapting.`},
          {className: 'smaller-text', text: `With the talented guidance of Vis à Vis Studio, the process of transforming her home inspired the homeowner to go on and start her own interior design studio, Le Studio Inne.`}
        ]
      }
    },
    {
      layout: 'doubleMedia',
      media: [
        {orientation: 'square', src: link('a-natural-system/6a.jpg')},
        {orientation: 'square', src: link('a-natural-system/6b.jpg')}
      ],
    },
    {
      layout: 'doubleMedia',
      media: [
        {orientation: 'square', src: link('a-natural-system/7a.jpg')},
        {orientation: 'square', src: link('a-natural-system/7b.jpg')}
      ],
    },
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          {className: 'smaller-text', text: `Despite its systematic design with a high degree of technical sophistication, Space Theory worked with the homeowners and Vis à Vis Studio to ensure the careful craft and detailing of white oak created a refined but homey feel in the kitchen—the sensation of nature within nature.`}
        ]
      }
    },
    {
      layout: 'media',
      media: [{orientation: 'horizontal', src: link('a-natural-system/8.jpg')}],
    },
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          {className: 'larger-text-space-after blue', text: `Why Space Theory? A Complete System`},
          {className: 'smaller-text-space-after', text: `Great aesthetics. Functional innovation. Premium quality.`},
          {render: () => (
            <Link className='larger-text text-item-link blue' to={'/page/a-complete-system'}>
              Learn More
            </Link>
          )}
        ]
      }
    }
  ]
};
