import {React} from 'components';
import {link} from './helpers';
import {Link} from 'react-router-dom';

// istanbul ignore file

export default {
  key: 'drawer-setup',
  title: `Insider’s Handbook: Drawer Setup`,
  sections: [
    {
      layout: 'text',
      heightAuto: true,
      hideLogo: true,
      text: {
        items: [
          {className: 'heading', text: `Insider’s Handbook: Drawer Setup`},
          {className: 'smaller-text', text: 'A guide to maximizing the storage built into your Space Theory system, starting with its drawers. From utensils and ingredients to kitchen tools and cleaning supplies, there is a place for everything.'}
        ]
      }
    },
    {
      layout: 'media',
      media: [{
        orientation: 'horizontal',
        src: link('drawer-setup/drawer-setup-sequence.mp4#t=0.1'),
        loop: true,
        autoplay: true,
        controls: false
      }]
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          "Our wooden and stainless steel drawer inserts can be easily arranged and rearranged by hand based on the purpose and location of each drawer."
        ]
      },
      media: [{
        orientation: 'square',
        src: link('drawer-setup/1.jpg')
      }],
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          "For example, a tidy serving setup may sit inside a drawer near an island or central gathering place, at the ready for guests and hungry spectators."
        ]
      },
      media: [{
        orientation: 'square',
        src: link('drawer-setup/2.jpg')
      }],
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          "Interior components come in a variety of sizes to accommodate different items."
        ]
      },
      media: [{
        orientation: 'square',
        src: link('drawer-setup/3.jpg')
      }],
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          "Inserts can take up a whole drawer or only part, leaving the rest of it flexible for storing larger items."
        ]
      },
      media: [{
        orientation: 'square',
        src: link('drawer-setup/4.jpg')
      }],
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          "Wooden components are handcrafted using domestically sourced hardwoods. Metal components are made with durable, dishwasher-safe stainless steel."
        ]
      },
      media: [{
        orientation: 'square',
        src: link('drawer-setup/5.jpg')
      }],
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          "Deep-drawer storage can be used to organize many small ingredients..."
        ]
      },
      media: [{
        orientation: 'square',
        src: link('drawer-setup/6.jpg')
      }],
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          "...or a handful of larger items."
        ]
      },
      media: [{
        orientation: 'square',
        src: link('drawer-setup/7.jpg')
      }],
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          "It’s up to you. And future you—once you decide to move things around."
        ]
      },
      media: [{
        orientation: 'square',
        src: link('drawer-setup/8.jpg')
      }],
    }
  ]
};
