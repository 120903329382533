import {_, React, Component, className} from 'components';
import DocumentMeta from 'react-document-meta';
import {Link} from 'react-router-dom';

import TextContainer from './text-container';
import Slideshow from '../slideshow/slideshow';
import Footer from 'components/app/page/footer/footer';
import './landing-page.scss';

export default class LandingPage extends Component {
  constructor(props) {
    super(props);

    this.secondFrameRef = React.createRef();
    this.state = {showScrollArrow: false};

    this.bind(['handleScrollArrowClick', 'handleScroll', 'evaluateScrollArrow', 'pictureElement']);
  }

  componentDidMount() {
    this.addStrucuredData();

    //HINT needed to make the scroll arrow work
    document.getElementById('root').classList.add('landing-page');
    document.getElementsByClassName('app')[0].classList.add('landing-page');

    window.addEventListener('scroll', this.handleScroll);

    this.evaluateScrollArrow();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    document.getElementById('root').classList.remove('landing-page');
  }

  addStrucuredData() {
    var script = document.createElement('script');

    script.setAttribute('type', 'application/ld+json');
    script.innerHTML = JSON.stringify({
      "@context": "https://schema.org",
      "@type": "Organization",
      "url": "https://www.spacetheory.com",
      "sameAs": ["https://www.facebook.com/spacetheorykitchens/", "https://www.instagram.com/space.theory/"],
      "logo": "https://spacetheory-public.s3.amazonaws.com/images/full-logo.jpg",
  	  "image": "https://spacetheory-public.s3.amazonaws.com/images/full-logo.jpg",
      "name": "Space Theory",
      "description": "An intelligent kitchen system from the founders of Henrybuilt. Made to order in Seattle for homes throughout North America.",
      "email": "seattle@spacetheory.com",
      "telephone": "+1-206-454-3160",
      "address": {
        "@type": "postalAddress",
        "streetAddress": "4632 Ohio Ave S",
        "addressLocality": "Seattle",
        "addressCountry": "WA",
        "addressRegion": "United States",
        "postalCode": "98134"
      }
    });

    document.querySelector('head').appendChild(script);
  }

  evaluateScrollArrow() {
    var frameHeight = this.secondFrameRef.current.getBoundingClientRect().height;
    var landingPageFramesContainerHeight = document.getElementsByClassName('landing-page-frames-container')[0].getBoundingClientRect().height;

    this.setState({showScrollArrow: window.scrollY + frameHeight < landingPageFramesContainerHeight});
  }

  handleScroll() {
    this.evaluateScrollArrow();
  }

  handleScrollArrowClick() {
    var scrollTo;
    var frameHeight = this.secondFrameRef.current.getBoundingClientRect().height;
    var landingPageFramesContainerHeight = document.getElementsByClassName('landing-page-frames-container')[0].getBoundingClientRect().height;

    if ((window.scrollY / frameHeight) % 1 === 0) { //HINT top of a frame
      scrollTo = window.scrollY + frameHeight;
    }
    else { //HINT between frames
      var nextFrameNumber = Math.ceil(window.scrollY / frameHeight);

      if ((window.scrollY / frameHeight) % 1 > 0.85) nextFrameNumber += 1; //HINT close to top of a frame should go to next one

      scrollTo = nextFrameNumber * frameHeight;
    }

    if (scrollTo + frameHeight > landingPageFramesContainerHeight) { //HINT prevent scrolling past last block
      scrollTo = landingPageFramesContainerHeight - frameHeight;

      this.setState({showScrollArrow: false});
    }

    window.scrollTo({
      top: scrollTo,
      behavior: 'smooth'
    });
  }

  pictureElement({filename, altText, loading='lazy'}) {
    var srcPrefix = 'https://spacetheory-public.s3.amazonaws.com/website/pages/landing/set-6';

    return (
      <picture>
        <source srcSet={`${srcPrefix}/narrow/${filename}.webp`} media='(max-width: 800px)' />
        <source srcSet={`${srcPrefix}/wide/${filename}.jpg`} media='(min-width: 801px)' />
        <img src={`${srcPrefix}/narrow/${filename}.webp`} alt={altText} loading={loading} />
      </picture>
    );
  }

  render() {
    return (
      <DocumentMeta
        title={'Space Theory'}
        description='An intelligent kitchen system from the founders of Henrybuilt. Made to order in Seattle for homes throughout North America.'
      >
        <div className='landing-page'>
          <div className='landing-page-frames-container'>
            <div className='landing-page-frame'>
              <div {...className(['text-container', 'align-center', 'title-text'])}>
                <div className='company-name'>
                  <span>SPACE</span><span>.THEORY</span>
                </div>
                <div className='tag-line'>KITCHENS</div>
                <div className='secondary-tag-line'>
                  <a className='landing-page-link' href='https://henrybuilt.com' target='_blank'>FROM THE CREATORS OF HENRYBUILT</a>
                </div>
              </div>
              <div className='media-container'>
                <div className='image-container'>
                  {this.pictureElement({filename: '1', altText: 'landing-page-1', loading: 'eager'})}
                </div>
              </div>
            </div>
            <div className='landing-page-frame'>
              <Slideshow
                allowFullscreen={false}
                enableKeyboardControls={false}
                controlsPlacement={'bottom'}
                showControlsBackground={false}
                context={'landing-page'}
                media={_.map(['a', 'b', 'c', 'd', 'e'], (letter) => {
                  return {
                    text: {
                      render: () => (
                        <TextContainer
                          items={[
                            {className: 'heading', text: 'Open Now: Space Theory Bay Area'},
                            {render: () => (
                              <div> Our first street-level retail showroom is now open in <a href='/page/mill-valley'>Mill Valley, California</a>, located in a 4,000-square-foot warehouse space directly next door to Henrybuilt. Walk in and take a look on weekdays from 10 a.m. to 5 p.m., or make an appointment at <a href='mailto:bayarea@spacetheory.com'>bayarea@spacetheory.com</a> for a free consultation with one of our team members.</div>
                            )},
                            {render: () => (
                              <div style={{marginTop: '1.5rem', fontSize: '90%', opacity: 0.85}}> If you’re located outside of the Bay Area, we have additional showrooms in <a href='/page/los-angeles'>Los Angeles</a>, <a href='/page/new-york'>New York</a>, and <a href='/page/seattle'>Seattle</a>.</div>
                            )}
                          ]}
                        />
                      )
                    },
                    medium: {
                      render: () => (this.pictureElement({filename: `2${letter}`, altText: `landing-page-2${letter}`}))
                    }
                  };
                })}
              />
            </div>
            <div className='landing-page-frame' ref={this.secondFrameRef}>
              <TextContainer
                items={[
                  {className: 'heading', text: 'Our Origin'},
                  {text: 'Space Theory was founded by the creators of Henrybuilt, the company behind the first American kitchen system. Their goal was to create a more affordable yet premium quality, well-designed performance kitchen.'}
                ]}
              />
              <div className='media-container'>
                <div className='image-container'>
                  {this.pictureElement({filename: '3', altText: 'landing-page-3'})}
                </div>
              </div>
            </div>
            <div className='landing-page-frame'>
              <Slideshow
                allowFullscreen={false}
                enableKeyboardControls={false}
                controlsPlacement={'bottom'}
                showControlsBackground={false}
                context={'landing-page'}
                media={[
                  {
                    text: {
                      render: () => (
                        <TextContainer
                          items={[
                            {className: 'heading', text: 'A Refined, High-Function Kitchen'},
                            {text: 'Our family of interrelated products give you great aesthetics, functional innovation, and premium quality.'}
                          ]}
                        />
                      )
                    },
                    medium: {
                      render: () => (this.pictureElement({filename: '4a', altText: 'landing-page-4a'}))
                    }
                  },
                  {
                    text: {
                      render: () => (
                        <TextContainer
                          items={[
                            {text: 'Flexible walls allow clear counters and for the things you need every day to be right where you want them.'}
                          ]}
                        />
                      )
                    },
                    medium: {
                      render: () => (this.pictureElement({filename: '4b', altText: 'landing-page-3b'}))
                    }
                  },
                  {
                    text: {
                      render: () => (
                        <TextContainer
                          items={[
                            {text: 'The award-winning Daylight Island Daylight is an open, flexible concept based on a 24” grid of welded steel. The grid-like structure enables a range of playful configurations of drawers, drawer fittings, appliances, and specialized storage units designed specifically for this island.'}
                          ]}
                        />
                      )
                    },
                    medium: {
                      render: () => (this.pictureElement({filename: '4c', altText: 'landing-page-4c'}))
                    }
                  },
                  {
                    text: {
                      render: () => (
                        <TextContainer
                          items={[
                            {text: 'Drawer storage bins allow you to move cooking ingredients from drawers onto the wall above the counter for easy access and an open worksurface.'}
                          ]}
                        />
                      )
                    },
                    medium: {
                      render: () => (this.pictureElement({filename: '4d', altText: 'landing-page-4d'}))
                    }
                  }
                ]}
              />
            </div>
            <div className='landing-page-frame'>
              <TextContainer
                items={[
                  {className: 'heading', text: 'A Holistic Approach Provides Integration and Flexibility'},
                  {text: 'We provide a complete design solution, with the refinement of detail and unique, built-in functionality that cannot be delivered by a traditional cabinetmaking approach.'}
                ]}
              />
              <div className='media-container'>
                <div className='image-container'>
                  {this.pictureElement({filename: '5', altText: 'landing-page-5'})}
                </div>
              </div>
            </div>
            <div className='landing-page-frame'>
              <TextContainer
                items={[
                  {className: 'heading', text: 'A Solid Foundation for Your Home'},
                  {text: 'Our goal is to create an anchor of quality and long-lasting livability. A “lifelong” product that is good for your investment, and mindful of the planet.'}
                ]}
              />
              <div className='media-container'>
                <div className='image-container'>
                  {this.pictureElement({filename: '6', altText: 'landing-page-6'})}
                </div>
              </div>
            </div>
            <div className='landing-page-frame'>
              <TextContainer
                items={[
                  {className: 'heading', text: 'Made to Order: Premium Quality'},
                  {text: 'Space Theory products are built in Seattle, Washington, in Henrybuilt’s production facility and shipped to your home wherever you live.'},
                  {text: 'Learn More About Our Quality', type: 'link', linkTo: '/page/premium-quality'}
                ]}
              />
              <div className='media-container'>
                <div className='image-container'>
                  {this.pictureElement({filename: '7', altText: 'landing-page-7'})}
                </div>
              </div>
            </div>
            <div className='landing-page-frame'>
              <TextContainer
                items={[
                  {className: 'heading', text: 'Custom Fit'},
                  {text: 'Each Space Theory kitchen is designed and built for a custom fit. Units are made to order based on the layout we create with you.'}
                ]}
              />
              <div className='media-container'>
                <div className='image-container'>
                  {this.pictureElement({filename: '8', altText: 'landing-page-8'})}
                </div>
              </div>
            </div>
            <div className='landing-page-frame'>
              <TextContainer
                items={[
                  {className: 'heading', text: 'Material and Finish'},
                  {text: 'Space Theory offers a refined palette of timeless and durable materials and finishes.'}
                ]}
              />
              <div className='media-container'>
                <div className='image-container'>
                  {this.pictureElement({filename: '9', altText: 'landing-page-9'})}
                </div>
              </div>
            </div>
            <div className='landing-page-frame'>
              <TextContainer
                items={[
                  {className: 'heading', text: 'When to Start'},
                  {text: 'Start as early as you can. Early involvement streamlines process, saves money, and can improve the outcome.'},
                  {text: 'Learn More About Our Process', type: 'link', linkTo: '/page/our-process'}
                ]}
              />
              <div className='media-container'>
                <div className='image-container'>
                  {this.pictureElement({filename: '10', altText: 'landing-page-10'})}
                </div>
              </div>
            </div>
            <div className='landing-page-frame'>
              <TextContainer
                items={[
                  {className: 'heading', text: 'Get in Touch'},
                  {text: 'If you are currently planning or working on a project, our team is ready to explore how Space Theory can support your vision. We work directly with our clients, not through dealers or third parties.'},
                  {render: () => (<div className='discuss-a-project' onClick={this.props.showInquiryForm}>Discuss a Project</div>)}
                ]}
              />
              <div className='media-container'>
                <div className='image-container'>
                  {this.pictureElement({filename: '11', altText: 'landing-page-11'})}
                </div>
              </div>
            </div>
            <div className='scroll-arrow-position-container'>
              <div className='left-container'>
                <div {...className(['scroll-arrow-container', ((this.state.showScrollArrow && window.innerWidth >= 801) ? 'visible' : 'hidden')])} onClick={ this.handleScrollArrowClick }>
                  <div className='arrow'></div>
                </div>
              </div>
              <div className='right-container'></div>
            </div>
          </div>
          <Footer environment={process.env.REACT_APP_IN_STAGING ? 'staging' : process.env.NODE_ENV}/>
        </div>
      </DocumentMeta>
    );
  }
}
