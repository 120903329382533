import {link} from './helpers';
import {gridCellGroupsFor} from './helpers';


// istanbul ignore file

export default {
  key: 'backsplash-counters-sinks',
  title: 'Backsplash, Counters, Sinks',
  sections: [
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          `Our Opencase system has been integrated into our backsplash panels.  The grid allows you to use the vertical space of your backsplash for easily accessible storage while leaving your counters clear for workspace.`
        ]
      },
      media: [{orientation: 'square', src: link('backsplash-counters-sinks/backsplash-counters-sinks-1.jpg')}]
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          `Space Theory offers a full kitchen solution with our solid surface acrylic and Paperstone countertops. These materials make ideal worksurface solutions. The solid surface acrylic (whites and light greys) are easy to maintain. Paperstone patinas over time reminiscent of aging leather and requires light maintenance.`,
          `Our countertops feature specific detailing that you can only get with Space Theory including drainfields, sink cutouts, and specialized edge details. Our bevel edge, for example, allows the countertop to appear thinner while still giving you the strength and functionality of a thicker counter.`
        ]
      },
      media: [{orientation: 'square', src: link('backsplash-counters-sinks/backsplash-counters-sinks-2.jpg')}]
    },
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          `...we can even provide the kitchen sink. Space Theory kitchen sinks are 18ga. stainless steel undermount sinks that are maximized to fit your base unit.  Sinks are available in 9” depth and include a center drain. Bottom grid, drain cover, strainer, and mounting hardware are included.`
        ]
      },
      media: [{orientation: 'square', src: link('backsplash-counters-sinks/backsplash-counters-sinks-3.jpg')}]
    }
  ]
}
