import {_, React, Component, className} from 'components'; //eslint-disable-line
import { Link } from 'react-router-dom';
import ExMachinaInquiryForm from 'components/app/ex-machina-inquiry-form/ex-machina-inquiry-form';
import ListItem from './list-item/list-item';
import Form from 'lib/form/form';
import pages from 'pages';
import './navigation.scss';

export default class Navigation extends Component {
  constructor(props) {
    super(props);

    this.state = {contactFormType: this.props.contactFormType};

    this.hideInquiryForm = () => this.toggleInquiryForm(false);

    this.bind(['handleListItemClick', 'toggleNavigation', 'handleContactTypeInputChange']);
  }

  handleListItemClick({page}) {
    if (page.key === 'contact') {
      this.toggleInquiryForm(true);
    }
  }

  toggleInquiryForm(inquiryFormIsVisible) {
    this.trigger('toggleInquiryForm', {inquiryFormIsVisible});
  }

  toggleNavigation() {
    this.props.toggleNavigation();
  }

  handleContactTypeInputChange({value}) {
    this.props.setContactFormType({value});
  }

  render() {
    var inquiryFormSrc = `/inquiry-form`;
    var {contactFormType} = this.props;
    if (this.props.contactReason) inquiryFormSrc += `?contactReason=${this.props.contactReason}`;

    this.showExMachinaInquiryForm = true;

    return (
      <div
        {...className([
          'app-navigation',
          this.props.isVisible && 'is-visible',
          this.inquiryFormIsVisible && 'inquiry-form-is-visible'
        ])}
      >
        <div className='app-navigation-icons-container'>
          <div {...className(['navigation-icon-container', this.props.isVisible ? 'close' : 'hamburger'])} onClick={this.toggleNavigation}>
            <img src={`https://s3.amazonaws.com/spacetheory-public/website/icons/black/${this.props.isVisible ? 'close' : 'hamburger'}.png`} alt='Toggle menu'/>
          </div>
          <Link className='home-icon-container' to={'/'}>
            <img src={`https://s3.amazonaws.com/spacetheory-public/website/icons/black/home.png`} alt='Homepage'/>
          </Link>
          <div className='discuss-a-project-container' onClick={this.props.showInquiryForm}>
            <div className='discuss-a-project-text'>Discuss a Project</div>
          </div>
        </div>
        {this.props.isVisible && (<div className='app-navigation-content'>
          <div className='inquiry-form-container'>
            <div className='hide-inquiry-form' onClick={this.hideInquiryForm}>← BACK</div>
            <div className='inquiry-form-section-container'>
              <div className='inquiry-form-section-content-container'>
                <div className='inquiry-form-section-text' style={{opacity: 0.8}}>
                  If you have a general inquiry, please email <a href='mailto:inquiries@spacetheory.com'>inquiries@spacetheory.com</a>.
                </div>
                <br/><br/>
                <div className='inquiry-form-section-title'>If you would like to discuss a project:</div>
                <div className='inquiry-form-section-text'>Please answer two quick questions, and we will connect you with a Space Theory team member who works with clients in your area. We work directly with our clients, never through dealers or third parties.</div>
                <div className='ex-machina-inquiry-form'>
                  <ExMachinaInquiryForm inquiryFormIsVisible={this.inquiryFormIsVisible}/>
                </div>
              </div>
            </div>
          </div>
          <div className='navigation-tray'></div>
          <div className='navigation-list'>
            {_.map(pages.tree, (page, p) => {
              if (!page.isUnlisted) {
                return (
                  <ListItem
                    index={p}
                    key={p}
                    page={page}
                    match={this.props.match}
                    on={{click: this.handleListItemClick}}
                    navigationIsVisible={this.props.isVisible}
                  />
                );
              }
            })}
            <div className='instagram-link'>
              <a href='https://www.instagram.com/space.theory/' target='_blank' rel='noopener noreferrer'>
                <img src='https://s3-us-west-2.amazonaws.com/henrybuilt-cdn/images/logo/instagram.png' alt=''/>
              </a>
            </div>
          </div>
        </div>)}
      </div>
    );
  }

  get inquiryFormIsVisible() {
    return this.props.inquiryFormIsVisible && this.props.isVisible;
  }
}
