import {_, React, Component, PropTypes} from 'components';
import Typing from 'react-typing-animation';

export default class SplashLogo extends Component {
  static propTypes = {
    page: PropTypes.shape({
      title: PropTypes.string.isRequired
    })
  }

  refs = {};

  constructor(props) {
    super(props);

    this.createRefs(['logoText']);
    this.id = Math.random();
    this.logoTextRef = React.createRef();
    this.state = {scrollTop: 0, windowHeight: window.innerHeight};
  }

  componentDidMount() {
    var scrollElement = this.props.pageSectionsRef.current;
    var scroll = scrollElement.onscroll;

    //WARNING addEventListener doesn't work for scroll events outside of window
    scrollElement.onscroll = (event) => {
      this.setState({scrollTop: scrollElement.scrollTop});
      //WARNING browser regression: scrollTop is only getting updated after scroll stops.

      //WARNING onscroll gets listened to by other components
      //WARNING this is here to retain that
      //TODO implement more robust solution that works with tracking
      if (scroll) scroll(event);
    };

    this.addDomEventListener({to: window, on: 'resize', handler: () => {
      // istanbul ignore next
      this.setState({windowHeight: window.innerHeight});
    }});
  }

  componentWillUnmount() {
    // istanbul ignore next
    if (this.props.pageSectionsRef.current) this.props.pageSectionsRef.current.onscroll = undefined;
  }

  createRefs(refKeys) {
    _.forEach(refKeys, refKey => this.refs[refKey] = React.createRef());
  }

  render() {
    var opacity = 1;

    if (this.logoTextRef.current) {
      var offsetTop = this.logoTextRef.current.getBoundingClientRect().top;
      var scrollTop = -(offsetTop - this.state.windowHeight + 200);

      opacity = 1 - (scrollTop / 300);
    }

    return (
      <div className='page-section-logo-text' ref={this.logoTextRef} style={{opacity}}>
        <div className='logo-company-title'>space.theory</div>
        <div className='logo-page-title'>
          <Typing startDelay={1000} speed={80}>{this.props.page.title}</Typing>
        </div>
      </div>
    );
  }
}
